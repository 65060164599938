import db from "../aws/dynamodb";
import {
  DEV_USER_ROLE_PERMISSIONS_TABLE_NAME,
  USER_ROLE_PERMISSIONS_TABLE_NAME,
} from "../constants";
import { getUserEMailNameByUsername } from "../sendgrid/users";
import { isDev } from "../utils/environment";
import { getAllStores } from "./store-table";
import { UserPermissions, UserRole, UserRolePermissionsItem } from "./types";

const TABLE_NAME = isDev ? DEV_USER_ROLE_PERMISSIONS_TABLE_NAME : USER_ROLE_PERMISSIONS_TABLE_NAME;

export async function addUserRolePermissionsRecord(
  userRolePermissionsItem: UserRolePermissionsItem,
) {
  try {
    await db.put({
      TableName: TABLE_NAME,
      Item: { ...userRolePermissionsItem, createdAt: new Date().toISOString() },
    });
  } catch (e) {
    console.log("ERROR ADDING USER ROLE PERMISSIONS", e);
    throw new Error("Error adding user role permissions");
  }
}

export async function getUserRolePermissionsByStoreId(
  storeId: string,
): Promise<UserRolePermissionsItem[]> {
  try {
    const { Items } = await db.query({
      TableName: TABLE_NAME,
      IndexName: "storeId-index",
      KeyConditionExpression: "storeId = :storeId",
      ExpressionAttributeValues: {
        ":storeId": storeId,
      },
      FilterExpression: "attribute_not_exists(agencyId)",
    });
    if (!Items) {
      return [];
    }

    return Items as UserRolePermissionsItem[];
  } catch (e) {
    console.log("ERROR GETTING USER ROLE PERMISSIONS", e);
    throw new Error("Error getting user role permissions");
  }
}

export async function changeUserRole({
  storeId,
  userId,
  newRole,
}: {
  storeId: string;
  userId: string;
  newRole: string;
}) {
  try {
    await db.update({
      TableName: TABLE_NAME,
      Key: {
        storeId,
        userId,
      },
      UpdateExpression: "set #role = :role",
      ExpressionAttributeNames: {
        "#role": "role",
      },
      ExpressionAttributeValues: {
        ":role": newRole,
      },
    });
  } catch (e) {
    console.log("ERROR CHANGING USER ROLE", e);
    throw new Error("Error changing user role");
  }
}

export async function changeUserPermissions({
  storeId,
  userId,
  permissions,
}: {
  storeId: string;
  userId: string;
  permissions: UserRolePermissionsItem["permissions"];
}) {
  try {
    await db.update({
      TableName: TABLE_NAME,
      Key: {
        storeId,
        userId,
      },
      UpdateExpression: "set #permissions = :permissions",
      ExpressionAttributeNames: {
        "#permissions": "permissions",
      },
      ExpressionAttributeValues: {
        ":permissions": permissions,
      },
    });
  } catch (e) {
    console.log("ERROR CHANGING USER PERMISSIONS", e);
    throw new Error("Error changing user permissions");
  }
}

export async function deleteUserRolePermissionsRecord({
  storeId,
  userId,
}: {
  storeId: string;
  userId: string;
}) {
  try {
    await db.delete({
      TableName: TABLE_NAME,
      Key: {
        storeId,
        userId,
      },
    });
  } catch (e) {
    console.log("ERROR DELETING USER ROLE PERMISSIONS", e);
    throw new Error("Error deleting user role permissions");
  }
}

export async function getUserRolePermissions({
  storeId,
  userId,
}: {
  storeId: string;
  userId: string;
}): Promise<
  | {
      role: UserRolePermissionsItem["role"];
      permissions: UserRolePermissionsItem["permissions"];
      agencyId: UserRolePermissionsItem["agencyId"];
    }
  | undefined
> {
  try {
    const { Item } = await db.get({
      TableName: TABLE_NAME,
      Key: {
        storeId,
        userId,
      },
    });
    if (!Item) {
      return undefined;
    }
    return {
      role: Item.role,
      permissions: Item.permissions,
      agencyId: Item.agencyId,
    };
  } catch (e) {
    console.log("ERROR GETTING USER ROLE AND PERMISSIONS", e);
    throw new Error("Error getting user permissions");
  }
}

export async function createFullAccessForAlStores() {
  const stores = await getAllStores();

  stores?.forEach(async (store) => {
    if (!store.owner) return;

    if (Array.isArray(store.owner)) {
      store.owner.forEach(async (owner) => {
        const userAttributes = await getUserEMailNameByUsername(owner);
        await addUserRolePermissionsRecord({
          userId: owner,
          storeId: store.id,
          role: UserRole.ADMIN,
          permissions: Object.values(UserPermissions),
          name: userAttributes?.name || "",
          email: userAttributes?.email || "",
        });
      });
    }
    if (typeof store.owner === "string") {
      const username = store.owner.split("::")[0];
      const userAttributes = await getUserEMailNameByUsername(username);
      await addUserRolePermissionsRecord({
        userId: username,
        storeId: store.id,
        role: UserRole.ADMIN,
        permissions: Object.values(UserPermissions),
        name: userAttributes?.name || "",
        email: userAttributes?.email || "",
      });
    }
    //when owner is Set
    if (!Array.isArray(store.owner) && typeof store.owner !== "string") {
      //@ts-ignore
      store.owner.forEach(async (owner: any) => {
        const userAttributes = await getUserEMailNameByUsername(owner);
        if (!userAttributes) return;
        await addUserRolePermissionsRecord({
          userId: owner,
          storeId: store.id,
          role: UserRole.ADMIN,
          permissions: Object.values(UserPermissions),
          name: userAttributes?.name || "",
          email: userAttributes?.email || "",
        });
      });
    }
  });
}

export async function deleteAllUsersForStore(storeId: string) {
  try {
    const userPermissions = await getUserRolePermissionsByStoreId(storeId);

    userPermissions?.forEach(async (user) => {
      await deleteUserRolePermissionsRecord({
        storeId,
        userId: user.userId,
      });
    });
    return `Deleted all users for storeId: ${storeId}`;
  } catch (e) {
    console.log("ERROR DELETING ALL USERS FOR STORE", e);
    return `Error deleting all users for storeId: ${storeId}`;
  }
}

export async function getUserRolePermissionsByUserId(
  userId: string,
): Promise<UserRolePermissionsItem[]> {
  try {
    const { Items } = await db.query({
      TableName: TABLE_NAME,
      KeyConditionExpression: "#userId = :userId",
      ExpressionAttributeValues: {
        ":userId": userId,
      },
      ExpressionAttributeNames: {
        "#userId": "userId",
      },
    });
    if (!Items) {
      return [];
    }

    return Items as UserRolePermissionsItem[];
  } catch (e) {
    console.log("ERROR GETTING USER ROLE PERMISSIONS", e);
    throw new Error("Error getting user role permissions");
  }
}

export async function addAgencyIdToUserRolePermissions({
  userId,
  agencyId,
}: {
  userId: string;
  agencyId: string;
}) {
  try {
    const userRolePermissions = await getUserRolePermissionsByUserId(userId);
    await Promise.all(
      userRolePermissions.map(async (userRolePermission) => {
        await db.update({
          TableName: TABLE_NAME,
          Key: {
            storeId: userRolePermission.storeId,
            userId,
          },
          UpdateExpression: "set #agencyId = :agencyId",
          ExpressionAttributeNames: {
            "#agencyId": "agencyId",
          },
          ExpressionAttributeValues: {
            ":agencyId": agencyId,
          },
        });
      }),
    );
  } catch (e) {
    console.log("ERROR ADDING AGENCY ID TO USER ROLE PERMISSIONS", e);
    throw new Error("Error adding agency id to user role permissions");
  }
}

async function getAllUserRolePermissionsRecords() {
  try {
    const { Items } = await db.scan({
      TableName: TABLE_NAME,
    });
    if (!Items) {
      return [];
    }

    return Items as UserRolePermissionsItem[];
  } catch (e) {
    console.log("ERROR GETTING ALL USER ROLE PERMISSIONS", e);
    throw new Error("Error getting all user role permissions");
  }
}

export async function addNewPermissionsOptionToAllUsers(newOption: UserPermissions) {
  const allUserRolePermissions = await getAllUserRolePermissionsRecords();

  allUserRolePermissions.forEach(async (record) => {
    //get current permissions
    const currentPermissions = record.permissions;
    //add recharge permissions if not already present
    if (!currentPermissions.includes(newOption)) {
      currentPermissions.push(newOption);
    }
    //update permissions
    await changeUserPermissions({
      storeId: record.storeId,
      userId: record.userId,
      permissions: currentPermissions,
    });
  });
}
