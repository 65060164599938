import React from "react";
import { useQueryClient } from "react-query";
import { useSession } from "next-auth/client";
import { useAuthContext } from "../../context/AmplifyContext";
import api from "../APIResolvers";

function useOutsideClick(elementRef: React.RefObject<any>, onOutsideClick: (event: any) => void) {
  React.useEffect(() => {
    function handleClickOutside(event: Event) {
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        onOutsideClick(event);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [elementRef, onOutsideClick]);
}

function useMounted() {
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}

function scrollToTop(value = 0, behavior?: "auto" | "smooth") {
  window.scrollTo({
    top: value,
    behavior,
  });
}

function useScrollToTop() {
  React.useEffect(() => {
    scrollToTop();
  }, []);
}

function useModalControls(initState = false) {
  const [isOpen, setIsOpen] = React.useState(initState);

  const openModal = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    openModal,
    closeModal,
    setIsOpen,
  };
}

function useAuthWindow(provider: string, storeId?: string) {
  const queryClient = useQueryClient();
  const { authedUser } = useAuthContext();
  const [authWindow, setAuthWindow] = React.useState<Window | null>(null);
  const [onAuthed, setOnAuthed] = React.useState(() => () => {});
  const [session, isLoading] = useSession();

  const openWindow = React.useCallback(
    async (onSuccess: () => void) => {
      if (!window) return undefined;
      setOnAuthed(() => onSuccess);

      setAuthWindow(
        window.open(
          `/signin?provider=${provider}`,
          "Auth",
          "width=1000,height=700,left=500,top=100,status=yes,toolbar=no,menubar=no,location=no",
        ),
      );
    },
    [provider],
  );

  React.useEffect(() => {
    if (authWindow && authedUser?.username) {
      const timer = setInterval(async () => {
        if (authWindow?.closed && session?.tokens.provider === provider) {
          const { accessToken, accessTokenExpires, refreshToken } = session.tokens;

          api.token
            .saveToken({
              accessToken,
              accessTokenExpires,
              refreshToken,
              userID: authedUser?.username,
              provider,
              storeId,
            })
            .then(() => {
              queryClient.invalidateQueries(`${provider}-token`);
            })
            .then(onAuthed);
          setAuthWindow(null);
          clearInterval(timer);
        }
      }, 500);

      return () => clearInterval(timer);
    }
  }, [
    authWindow,
    authWindow?.closed,
    authedUser?.username,
    onAuthed,
    provider,
    queryClient,
    session,
    isLoading,
    storeId,
  ]);

  return { openWindow };
}

export { useOutsideClick, useScrollToTop, useMounted, useModalControls, useAuthWindow };
