export const SENDGRID_API_KEY = process.env.SENDGRID_API_KEY || "";
export const EMAIL_FROM = "info@storehero.ai";
export const TEMPLATE_ID = "d-bc2cf5ab8c974982a5666a72c8362ed5";
export const STORE_TABLE_NAME = "Store-d7wkzja7rzgxjkb752nyq2wfci-staging";
export const CURRENCY_TABLE_NAME = "Currency-d7wkzja7rzgxjkb752nyq2wfci-staging";
export const RUTTER_CONNECTIONS_TABLE_NAME = "RutterConnections";
export const COST_SETTINGS_TABLE_NAME = "CostSettings";
export const OTHER_COST_SETTINGS_TABLE_NAME = "OtherCostSettings";
export const PINNED_CARDS_TABLE_NAME = "PinnedCards";
export const INDUSTRY_TABLE_NAME = "Industry-d7wkzja7rzgxjkb752nyq2wfci-staging";
export const ROLE_TABLE_NAME = "Role-d7wkzja7rzgxjkb752nyq2wfci-staging";
export const ADDITIONAL_REPORTING_EMAILS_TABLE_NAME = "AdditionalReportingEmails";
export const SHOPIFY_UNIT_COSTS_TABLE_NAME = "ShopifyUnitCosts";
export const FILTERS_TABLE_NAME = "Filters";
export const META_ADS_CURRENCY_SETTINGS = "MetaAdsCurrencySetting";
export const ADS_CURRENCY_SETTINGS = "AdsCurrencySetting";
export const MARKET_COST_SETTINGS = "MarketCostSettings";

export const SHOPIFY_UNIT_COSTS_S3_BUCKET_NAME = "shopify-unit-costs";
export const COSTS_SETTINGS_S3_BUCKET_NAME = "costs-setting";
export const COGS_CSV_FILES = "cogs-csv";
export const WEBHOOK_URL = "https://webhooks.storehero.ai";
export const WEBHOOK_PORT = 4000;
export const LOGOS_S3_BUCKET_NAME = "storehero-whitelabel-logos";
export const WHITE_LABEL_TABLE_NAME = "WhiteLabel";
export const TOKENS_TABLE_NAME = "tokens";
export const ACCESS_TOKENS_TABLE_NAME = "accessTokensForStore";
export const FB_RATE_LIMIT_TABLE_NAME = "FBRateLimit";
export const DASHBOARD_SECTIONS = "DashboardSections";
export const TEMPLATE_ID_SYNC_COMPLETE = "d-26fe33972f374732bbe9ba098371f035";
export const INVITES_TABLE_NAME = "Invites";
export const USER_ROLE_PERMISSIONS_TABLE_NAME = "UserRolePermissions";
export const AGENCIES_TABLE_NAME = "Agencies";
export const AGENCY_MEMBERS_TABLE_NAME = "AgencyMembers";
export const AGENCY_INVITES_TABLE_NAME = "AgencyInvites";
export const AGENCY_WHITE_LABEL_TABLE_NAME = "AgencyWhitelabel";
export const JUICE_EMAIL_FROM = "support@mail.getmejuice.com";
export const CRON_JOBS_LOGS_S3_BUCKET_NAME = "cron-jobs-logs";
export const SHOPIFY_ORDERED_REPORTS_TABLE_NAME = "shopifyUnitCostsOrderedReports";
export const STORE_ADDED_TO_JUICE_TEMPLATE_ID = "d-69601fbb5215420190c2f08be2d92ac1";
export const EMAIL_REPORTING_TABLE_NAME = "EmailReporting";
export const SHIP_BOB_WEBHOOKS_TABLE_NAME = "ShipBobWebhooks";
export const SHOPIFY_APP_INSTALLS_TABLE_NAME = "ShopifyAppInstalls";
export const PAID_PLANS_TABLE_NAME = "PaidPlans";
export const ONBOARDING_CHECKLIST_TABLE_NAME = "OnboardingChecklist";

export const DEV_STORE_TABLE_NAME = "Store-dev";
export const DEV_USER_ROLE_PERMISSIONS_TABLE_NAME = "UserRolePermissions-dev";

export const JUICE_AGENCY_ID = "1c1e914b-25e3-4dac-93d7-8f19ea878224";
export const SWAP_AGENCY_ID = "0fb237cf-4c27-4157-bb4d-6ababdf6eff7";
export const QUANTAXCO_AGENCY_ID = "a020c12d-6b6f-4521-a213-4a6dcdee3ab8";
export const PAASE_AGENCY_ID = "41316fd4-b106-46dc-82e2-e49b61b0fd9a";

export const QUANTAXCO_EMAIL_FROM = "paras@quantaxco.uk";
export const PAASE_EMAIL_FROM = "analytics@paase.ai";
